<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pa-2 pa-md-0>
      <v-flex xs12 align-self-center style="margin-all: 0px;">
        <v-layout wrap justify-center>
          <v-flex
            xs12
            lg10
            :style="
              $vuetify.breakpoint.name == 'xs'
                ? 'margin-top:0%'
                : 'padding-top:0%'
            "
            align-self-center
          >
            <v-layout wrap>
              <v-flex xs12 align-self-center text-center pt-8>
                <span style="font-size:26px;color:#000" class="poppinsbold">
                  Securing Right of Passage for Asian Elephants
                </span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center py-0>
              <v-flex xs12 align-self-center pt-10>
                <v-card tile flat outlined color="#ffffff">
                  <v-layout wrap justify-center>
                    <v-flex xs12 md6 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-center pa-4>
                            <span
                              class="poppinsregular"
                              :style="
                                $vuetify.breakpoint.name == 'xs'
                                  ? 'font-size:16px;color:#000'
                                  : 'font-size:20px;color:#000'
                              "
                            >
                              <span
                                :style="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'font-size:14px'
                                    : $vuetify.breakpoint.name == 'xs'
                                    ? 'font-size:17px'
                                    : 'font-size:19px'
                                "
                              >
                                Large herbivores like elephants intrinsically
                                need vast areas to roam. And with India’s forest
                                cover rapidly fragmenting due to exponential
                                increase in agricultural and infrastructural
                                land requirements, land for both humans and
                                elephants seems insufficient. The problem of
                                human- elephant conflict is becoming
                                unmanageable.
                                <span class="poppinsbold">
                                  The only way forward is co-existence.
                                </span>
                                <br />
                                <br />

                                WTI and partners have identified
                                <span class="poppinsbold"
                                  >101 elephant corridors</span
                                >
                                – natural strips of land that connect fragmented
                                forest patches and provide safe, human-free
                                passages to hundreds of species, including
                                migratory Asian elephants. Protecting these
                                ‘corridors’ is critical especially because a
                                large chunk of country’s elephant habitat is
                                unprotected – susceptible to encroachment or
                                already in use by humans.
                              </span>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 md6 align-self-center>
                      <v-img
                        contain
                        :src="require('./../../assets/images/Ele1.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-0 pb-0>
              <v-flex xs12 align-self-center pt-0>
                <v-card tile flat outlined color="#ffffff">
                  <v-layout wrap justify-center>
                    <v-flex
                      xs12
                      md6
                      align-self-center
                      class="hidden-sm-and-down"
                    >
                      <v-img
                        contain
                        :src="require('./../../assets/images/Ele2.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex xs12 md6 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-center pa-4>
                            <span
                              class="poppinsregular"
                              :style="
                                $vuetify.breakpoint.name == 'xs'
                                  ? 'font-size:16px;color:#000'
                                  : 'font-size:20px;color:#000'
                              "
                            >
                              <span
                                :style="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'font-size:14px'
                                    : $vuetify.breakpoint.name == 'xs'
                                    ? 'font-size:17px'
                                    : 'font-size:19px'
                                "
                              >
                                <span class="poppinsbold">
                                  In the two decades that the Right of Passage
                                  project has been active
                                </span>
                                we have appointed 13 local teams (called Green
                                Corridor Champions) to monitor corridors and
                                track elephant movement to keep both elephants
                                and humans safe.

                                <br />
                                <br />
                                Four corridor land parcels under private
                                ownership have been purchased and handed over to
                                the forest department for legal protection.

                                <br />
                                <br />
                                We have assisted local communities to set-aside
                                20 Village Reserve Forests (VRFs) or
                                community-owned lands for elephant protection in
                                exchange for infrastructure and livelihood
                                support.

                                <br />
                              </span>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 align-self-center class="hidden-md-and-up">
                      <v-img
                        contain
                        :src="require('./../../assets/images/Ele2.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-0 pb-0>
              <v-flex xs12 align-self-center pt-10>
                <v-card tile flat outlined color="#92D050">
                  <v-layout wrap justify-center pa-2>
                    <v-flex xs12 md10 lg6 align-self-center>
                      <a
                        title="Click on the image to zoom"
                        :href="require('./../../assets/images/Ele3.jpg')"
                      >
                        <v-img
                          contain
                          :src="require('./../../assets/images/Ele3.jpg')"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#527E33"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </a>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-0 pb-0>
              <v-flex xs12 align-self-center pt-10>
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex xs12 md6 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-center pa-4>
                            <span
                              class="poppinsregular"
                              :style="
                                $vuetify.breakpoint.name == 'xs'
                                  ? 'font-size:17px;color:#000'
                                  : 'font-size:19px;color:#000'
                              "
                            >
                              <span style="font-size:22px" class="poppinsbold">
                                WHY SHOULD WE CARE WHAT HAPPENS TO ELEPHANTS?
                              </span>
                              <br />
                              <br />
                              <span
                                :style="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'font-size:14px'
                                    : 'font-size:19px'
                                "
                              >
                                Elephants are an important cultural and
                                religious icon in India.

                                <br />
                                <br />
                                Elephants are important grazers and browsers,
                                consuming vast amounts of vegetation each day
                                while spreading seeds around as they go.
                                <br />
                                <br />
                                What Bagheera says in the ‘The Jungle Book’ is a
                                fact. “The elephants created this jungle. They
                                made all that belongs”.

                                <br />
                                <br />
                                Elephants are the architects of forests. As they
                                roam, they create clearings and gaps which allow
                                sunlight to reach new seedlings, helping plants
                                to grow and forests to regenerate naturally.

                                <br />
                                <br />
                                Elephants dig wells to access underground water
                                thereby provide watering holes not just for
                                themselves but for other creatures as well.
                              </span>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>

                    <v-flex xs12 md6 align-self-center>
                      <v-img
                        contain
                        :src="require('./../../assets/images/Ele4.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-2 pb-6>
              <v-flex xs12 align-self-center pt-10>
                <v-card tile flat outlined color="#527E33">
                  <v-layout wrap justify-center>
                    <v-flex xs12 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex
                            xs12
                            align-self-center
                            text-xs-center
                            text-sm-justify
                            pa-4
                          >
                            <span
                              class="poppinsregular"
                              :style="
                                $vuetify.breakpoint.name == 'xs'
                                  ? 'font-size:16px;color:#ffffff'
                                  : 'font-size:20px;color:#ffffff'
                              "
                            >
                              <v-layout wrap>
                                <v-flex xs12 align-self-center text-center>
                                  <span
                                    style="font-size:24px"
                                    class="poppinsbold"
                                  >
                                    FOUR APPROACHES FOR PROTECTING ELEPHANT
                                    CORRIDORS IN INDIA
                                  </span>
                                </v-flex>
                              </v-layout>
                              <br />
                              <br />
                              <span
                                class="poppinssemibold"
                                style="border-bottom:2px solid #ffffff;"
                              >
                                Private Purchase Model:
                              </span>
                              <span style="color:#ffffff">
                                With our implementation partners we directly
                                purchase the land, rehabilitate affected local
                                people, and transfer the land to the relevant
                                state forest department for legal protection.
                              </span>

                              <br />
                              <br />

                              <span
                                class="poppinssemibold"
                                style="border-bottom:2px solid #ffffff;"
                              >
                                Community Securement Model:
                              </span>
                              <span style="color:#ffffff">
                                Community-owned lands are set aside through
                                easements or bilateral benefit sharing models.
                              </span>

                              <br />
                              <br />

                              <span
                                class="poppinssemibold"
                                style="border-bottom:2px solid #ffffff;"
                              >
                                Government Acquisition Model:
                              </span>
                              <span style="color:#ffffff">
                                The focus here is on policy advocacy work and
                                providing national and state governments with
                                technical assistance and ‘soft hands’ for the
                                acquisition of key corridors.
                              </span>

                              <br />
                              <br />
                              <span
                                class="poppinssemibold"
                                style="border-bottom:2px solid #ffffff;"
                              >
                                Public Securement Model:
                              </span>
                              <span style="color:#ffffff">
                                This model envisages the creation of a network
                                of empowered local stakeholders called Green
                                Corridor Champions that ensure every corridor is
                                monitored in perpetuity.
                              </span>

                              <br />
                              <br />
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start pt-2 pb-6 pa-md-4 pa-lg-0>
              <v-flex xs12 align-self-center pa-1 pa-sm-0 py-sm-1>
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex xs12 align-self-center text-center py-2>
                      <span
                        style="font-size:20px"
                        class="poppinsbold hidden-sm-and-up"
                      >
                        THE GAJ YATRA CAMPAIGN
                      </span>
                    </v-flex>
                    <v-flex xs12 sm3 align-self-center class="hidden-xs-only">
                      <v-img
                        contain
                        :src="require('./../../assets/images/Ele5.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex xs12 sm9 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-center pa-4>
                            <span
                              class="poppinsregular"
                              style="font-size:17px;color:#000"
                            >
                              <span class="poppinsbold hidden-xs-only">
                                THE GAJ YATRA CAMPAIGN
                                <br />
                                <br />
                              </span>

                              <!-- <br />
                              <br /> -->
                              Gaj Yatra is a unique campaign that aims to raise
                              awareness about the shrinking space for India’s
                              wild elephants and the importance of securing
                              elephant corridors. Gaj Yatra’s objective is
                              two-fold: to raise awareness about the plight of
                              India’s National Heritage Animal, and to build a
                              groundswell of popular support for the securing of
                              elephant corridors.
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>

                    <v-flex xs12 sm3 align-self-center class="hidden-sm-and-up">
                      <v-img
                        contain
                        :src="require('./../../assets/images/Ele5.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 align-self-center pa-1 pa-sm-0 py-sm-1>
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm3 align-self-center class="hidden-xs-only">
                      <v-img
                        contain
                        :src="require('./../../assets/images/Ele6.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex xs12 sm9 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-center py-2>
                            <span
                              style="font-size:20px"
                              class="poppinsbold hidden-sm-and-up"
                            >
                              GAJA 'MITRAS' IN MEDIA
                            </span>
                          </v-flex>
                          <v-flex xs12 align-self-center text-center pa-4>
                            <span
                              class="poppinsregular"
                              style="font-size:17px;color:#000"
                            >
                              <span class="poppinsbold hidden-xs-only">
                                GAJA 'MITRAS' IN MEDIA
                                <br />
                                <br />
                              </span>

                              <!-- <br />
                              <br /> -->
                              It is critical that conservation practitioners
                              engage with the media as they are often reliant on
                              it to communicate their goals to the public and to
                              keep policy makers informed. Media can act as a
                              fulcrum for engaging government, institutional,
                              and public support for elephant conservation and
                              mobilising action for it.
                              <br />
                              <br />
                              To do this, WTI is organising
                              <span class="poppinsbold">Media conclaves</span>
                              where senior editors and owners of media houses
                              will be invited to participate with the aim of
                              developing a network of media representatives-
                              Gaja Mitras. The objective is to highlight
                              elephant corridor matters and concerns positively
                              in regional mainstream media.
                            </span>
                          </v-flex>
                          <v-flex
                            xs12
                            sm3
                            align-self-center
                            class="hidden-sm-and-up"
                          >
                            <v-img
                              contain
                              :src="require('./../../assets/images/Ele6.jpg')"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="#527E33"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start pt-2 pb-6 pa-md-4 pa-lg-0 py-lg-4>
              <v-flex xs12 align-self-center>
                <v-card
                  outlined
                  flat
                  tile
                  color="#ffffff"
                  style="border:2px solid #527E33"
                >
                  <v-layout wrap pa-2>
                    <v-flex xs12 align-self-center text-center pa-2>
                      <span class="poppinssemibold">
                        Your support today will strengthen our efforts to
                        protect elephant corridors in India.
                      </span>
                    </v-flex>

                    <v-flex xs12 align-self-center text-center pa-2>
                      <v-btn tile large dark color="#e27725" @click="$vuetify.breakpoint.name== 'xs' || $vuetify.breakpoint.name== 'sm'?scrollMeXS():scrollMe()">
                        <span
                          class="poppinsbold"
                          :style="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'font-size:17px'
                              : 'font-size:24px'
                          "
                        >
                          HELP ASIAN ELEPHANTS
                        </span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout
              wrap
              justify-start
              pt-2
              pb-6
              pa-md-4
              pa-lg-0
              py-lg-4
              pb-lg-8
            >
              <v-flex xs12 align-self-center>
                <v-card outlined flat tile color="#527E33">
                  <v-layout wrap pa-2>
                    <v-flex xs12 sm3 align-self-center text-center pa-2>
                      <v-img
                        contain
                        height="100px"
                        :src="require('./../../assets/images/wtilogo.png')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>

                    <v-flex
                      xs12
                      sm9
                      align-self-center
                      text-sm-left
                      text-xs-center
                      pa-2
                      style="color:#ffffff"
                    >
                      <span class="poppinsbold">
                        FOR A SECURE NATURAL HERITAGE OF INDIA
                      </span>
                      <br />
                      <br />
                      <span class="poppinssemibold">
                        CONTACT INFO
                        <br />
                        <span class="poppinsregular">
                          Address: F-13, Sector 8, National Capital Region
                          (NCR), Noida 201301, India Email: info@wti.org.in
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  methods: {
    scrollMe() {
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;

      document.getElementById("donateNow").scrollIntoView({
        behavior: "smooth",
      });
    },
    scrollMeXS() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      document.getElementById("donateNow").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
.texth {
  line-height: 1.5em;
  min-height: 9.5em;
  float: left;
  width: 100%;
}
.hovCls {
  color: #005f32;
  border-bottom: 3px solid #005f32;
}

.hovCls:hover {
  color: blue;
  border-bottom: 3px solid blue;
}
</style>
